import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createAuth0 } from '@auth0/auth0-vue'
import authConfig from '../auth_config.json'
import VueCookies from 'vue-cookies'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css' // import Notyf styles
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://781ae8cb3ce745579c90b77b6bf996c2@o4504881030889472.ingest.sentry.io/4504881037049856',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'my-site-url.com', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VueCookies)
app.use(
  createAuth0({
    domain: authConfig.domain,
    clientId: authConfig.client_id,
    audience: authConfig.audience,
    authorizationParams: {
      redirect_uri: window.location.origin
    }
  })
)

// Add Notyf to the app
app.config.globalProperties.$notyf = new Notyf()

app.mount('#app')
