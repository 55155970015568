import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'ホーム',
      requiresAuth: true
    },
    component: function () {
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      title: 'ダッシュボード',
      requiresAuth: true
    },
    component: function () {
      return import('../views/DashboardView.vue')
    }
  },
  {
    path: '/subscription',
    name: 'subscription',
    meta: {
      title: '契約情報',
      requiresAuth: true
    },
    component: function () {
      return import('../views/SubscriptionView.vue')
    }
  },
  {
    path: '/order_list',
    name: 'order_list',
    meta: {
      title: '受注一覧',
      requiresAuth: true
    },
    component: function () {
      return import('../views/OrdersView.vue')
    }
  },
  {
    path: '/order_list/:id',
    name: 'order_detail',
    meta: {
      title: '受注詳細',
      requiresAuth: true
    },
    component: function () {
      return import('../views/OrderDetailView.vue')
    },
    props: true
  },
  {
    path: '/preparing_shipping_list',
    name: 'preparing_shipping_list',
    meta: {
      title: '出荷準備中',
      requiresAuth: true
    },
    component: function () {
      return import('../views/PreparingShippingView.vue')
    }
  },
  {
    path: '/shipping_list',
    name: 'shipping_list',
    meta: {
      title: '出荷済み',
      requiresAuth: true
    },
    component: function () {
      return import('../views/ShippingView.vue')
    }
  },
  {
    path: '/products',
    name: 'products',
    meta: {
      title: '商品管理',
      requiresAuth: true
    },
    component: function () {
      return import('../views/ProductsView.vue')
    }
  },
  {
    path: '/products/:id',
    name: 'product_detail',
    meta: {
      title: '商品詳細',
      requiresAuth: true
    },
    component: function () {
      return import('../views/ProductDetailView.vue')
    },
    props: true
  },
  {
    path: '/stock_check',
    name: 'stock_check',
    meta: {
      title: '在庫管理',
      requiresAuth: true
    },
    component: function () {
      return import('../views/StockCheckView.vue')
    }
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: 'プロフィール',
      requiresAuth: true
    },
    component: function () {
      return import('../views/ProfileView.vue')
    }
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      title: '各種設定',
      requiresAuth: true
    },
    component: function () {
      return import('../views/SettingsView.vue')
    }
  },
  /*
  {
    path: '/signup',
    name: 'signup',
    meta: { title: '新規登録' },
    component: function () {
      return import('../views/SignupView.vue')
    }
  },
  */
  {
    path: '/login',
    name: 'login',
    meta: { title: 'ログイン' },
    component: function () {
      return import('../views/LoginView.vue')
    }
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { title: 'ログイン' },
    component: function () {
      return import('../views/CallbackView.vue')
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      title: 'ログアウト',
      requiresAuth: true
    },
    component: function () {
      return import('../views/LogoutView.vue')
    }
  },
  {
    path: '/pdf-generator',
    name: 'PDFGenerator',
    meta: {
      title: '領収書生成',
      requiresAuth: true
    },
    component: function () {
      return import('../views/PDFGeneratorView.vue')
    }
  },
  /*
  {
    path: '/mobile_closed',
    name: 'mobile_closed',
    component: function () {
      return import('../views/MobileClosedView.vue')
    }
  },
  */
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    meta: {
      title: '存在しないページ',
      requiresAuth: true
    },
    component: function () {
      return import('../views/NotFoundView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
