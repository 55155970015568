import { createStore } from 'vuex'

export default createStore({
  state: {
    mobile: false,
    smartphone: false,
    mobileSidebarOpen: false,
    otherAuthError: false,
    auth: {
      isAuth: false,
      token: null,
      user: null
    },
    other_auth: {
      isTokenGet: false,
      minne_link_setting: null,
      minne_auth_token: null,
      creema_link_setting: null,
      creema_session: null,
      creema_xsrf_token: null,
      base_link_setting: null,
      base_accesstoken: null,
      base_refreshtoken: null,
      base_refreshtoken_expired: false
    },
    profile: {
      nickname: null,
      shopname: null,
      img: null
    },
    orders: {
      response: null
    },
    settings: {
      CustomeFilds: {
        fieldName1: '項目1',
        fieldName2: '項目2',
        fieldName3: '項目3',
        fieldName4: '項目4',
        fieldName5: '項目5'
      }
    }
  },
  getters: {
    getotherAuthError (state) {
      return state.otherAuthError
    },
    getUser (state) {
      return state.auth.user
    },
    getisAuth (state) {
      return state.auth.isAuth
    },
    getAuthToken (state) {
      return state.auth.token
    },
    get_isTokenGet (state) {
      return state.other_auth.isTokenGet
    },
    get_minne_link_setting (state) {
      return state.other_auth.minne_link_setting
    },
    get_minne_auth_token (state) {
      return state.other_auth.minne_auth_token
    },
    get_creema_link_setting (state) {
      return state.other_auth.creema_link_setting
    },
    get_creema_session (state) {
      return state.other_auth.creema_session
    },
    get_creema_xsrf_token (state) {
      return state.other_auth.creema_xsrf_token
    },
    get_base_link_setting (state) {
      return state.other_auth.base_link_setting
    },
    get_base_accesstoken (state) {
      return state.other_auth.base_accesstoken
    },
    get_base_refreshtoken (state) {
      return state.other_auth.base_refreshtoken
    },
    get_base_refreshtoken_expired (state) {
      return state.other_auth.base_refreshtoken_expired
    },
    get_timetree_access_token (state) {
      return state.other_auth.timetree_access_token
    },
    get_timetree_calendar_id (state) {
      return state.other_auth.timetree_calendar_id
    },
    get_profile_nickname (state) {
      return state.profile.nickname
    },
    get_profile_shopname (state) {
      return state.profile.shopname
    },
    get_profile_img (state) {
      return state.profile.img
    },
    get_orders_response (state) {
      return state.orders.response
    },
    get_settings_customefilds (state) {
      return state.settings.CustomeFilds
    }
  },
  mutations: {
    set_otherAuthError (state, bloolean) {
      state.otherAuthError = bloolean
    },
    set_mobile (state, bloolean) {
      state.mobile = bloolean
    },
    set_smartphone (state, bloolean) {
      state.smartphone = bloolean
    },
    saveToken (state, token) {
      state.auth.token = token
    },
    removeToken (state) {
      state.auth.token = null
    },
    saveUser (state, user) {
      state.auth.user = user
    },
    setisAuth (state, boolean) {
      state.auth.isAuth = boolean
    },
    save_isTokenGet (state, boolean) {
      state.other_auth.isTokenGet = boolean
    },
    save_minne_link_setting (state, option) {
      state.other_auth.minne_link_setting = option
    },
    save_minne_auth_token (state, token) {
      state.other_auth.minne_auth_token = token
    },
    save_creema_link_setting (state, option) {
      state.other_auth.creema_link_setting = option
    },
    save_creema_session (state, session) {
      state.other_auth.creema_session = session
    },
    save_creema_xsrf_token (state, token) {
      state.other_auth.creema_xsrf_token = token
    },
    save_base_link_setting (state, option) {
      state.other_auth.base_link_setting = option
    },
    save_base_accesstoken (state, accesstoken) {
      state.other_auth.base_accesstoken = accesstoken
    },
    save_base_refreshtoken (state, refreshtoken) {
      state.other_auth.base_refreshtoken = refreshtoken
    },
    save_base_refreshtoken_expired (state, boolean) {
      state.other_auth.base_refreshtoken_expired = boolean
    },
    save_profile_nickname (state, nickname) {
      state.profile.nickname = nickname
    },
    save_profile_shopname (state, shopname) {
      state.profile.shopname = shopname
    },
    save_profile_img (state, url) {
      state.profile.img = url
    },
    save_orders_response (state, response) {
      state.orders.response = response
    },
    save_settings_timetree (state, boolean) {
      state.settings.timetree = boolean
    },
    save_settings_custom_field (state, { fieldName, fieldValue }) {
      state.settings.CustomeFilds[fieldName] = fieldValue
    }
  },
  actions: {
    saveToken ({ commit }, token) {
      commit('saveToken', token)
    },
    saveUser ({ commit }, user) {
      commit('saveUser', user)
    },
    setisAuth ({ commit }, boolean) {
      commit('setisAuth', boolean)
    },
    save_isTokenGet ({ commit }, boolean) {
      commit('save_isTokenGet', boolean)
    },
    save_minne_link_setting ({ commit }, option) {
      commit('save_minne_link_setting', option)
    },
    save_minne_auth_token ({ commit }, token) {
      commit('save_minne_auth_token', token)
    },
    save_creema_link_setting ({ commit }, option) {
      commit('save_creema_link_setting', option)
    },
    save_creema_session ({ commit }, session) {
      commit('save_creema_session', session)
    },
    save_creema_xsrf_token ({ commit }, token) {
      commit('save_creema_xsrf_token', token)
    },
    save_base_link_setting ({ commit }, option) {
      commit('save_base_link_setting', option)
    },
    save_base_accesstoken ({ commit }, accesstoken) {
      commit('save_base_accesstoken', accesstoken)
    },
    save_base_refreshtoken ({ commit }, refreshtoken) {
      commit('save_base_refreshtoken', refreshtoken)
    },
    save_base_refreshtoken_expired ({ commit }, boolean) {
      commit('save_base_refreshtoken_expired', boolean)
    },
    save_profile_nickname ({ commit }, nickname) {
      commit('save_profile_nickname', nickname)
    },
    save_profile_shopname ({ commit }, shopname) {
      commit('save_profile_shopname', shopname)
    },
    save_profile_img ({ commit }, url) {
      commit('save_profile_img', url)
    },
    save_orders_response ({ commit }, response) {
      commit('save_orders_response', response)
    },
    save_settings_custom_field ({ commit }, payload) {
      commit('save_settings_custom_field', payload)
    }
  },
  modules: {
  }
})
