<template>
  <TopMenu />
  <SideMenu />
  <router-view />
  <footer id="footer">
    <p id="page-top-button"><a @click="goToTop"></a></p>
  </footer>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import SideMenu from '@/components/SideMenu.vue'

export default {
  name: 'HomeView',
  data () {
    return {
      user: null,
      token: null,
      auth0_isLoading: this.$auth0.isLoading,
      routeInstance: this.$route
    }
  },
  computed: {
    mobile () {
      return this.$store.state.WebrtcHidden
    }
  },
  components: {
    TopMenu,
    SideMenu
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.pageTopAnime)
  },
  watch: {
    '$route' (routeInstance) {
      this.createTitleDesc(routeInstance)
      if (!this.auth0_isLoading && routeInstance !== undefined) {
        this.loginCheck(routeInstance)
      }
    },
    auth0_isLoading: {
      handler (date) {
        if (!date && this.routeInstance !== undefined) {
          this.loginCheck(this.routeInstance)
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted () {
    await this.$auth0.getAccessTokenSilently({ detailedResponse: true, scope: 'read:current_user' })
      .then(async r => {
        this.token = r.id_token
        this.user = this.$auth0.user
        this.$cookies.set('auth_token', this.token, 30)
        this.$store.dispatch('saveToken', this.token)
        this.$store.dispatch('saveUser', this.user)
        this.$store.dispatch('setisAuth', true)
      })

    this.profileGetter(this.token)
    await this.otherAuthGetter(this.token)
    await this.otherAuthCheck()

    window.addEventListener('scroll', this.pageTopAnime)
    this.pageTopAnime()

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  methods: {
    handleResize () {
      if (window.innerWidth >= 800 && window.innerWidth <= 1200) {
        this.$store.commit('set_mobile', true)
        this.$store.commit('set_smartphone', false)
      } else if (window.innerWidth <= 800) {
        this.$store.commit('set_mobile', true)
        this.$store.commit('set_smartphone', true)
      } else {
        this.$store.commit('set_mobile', false)
        this.$store.commit('set_smartphone', false)
      }
    },
    createTitleDesc: function (routeInstance) {
      console.log(routeInstance)
      if (routeInstance.meta.title) {
        const setTitle = routeInstance.meta.title + ' - maider'
        document.title = setTitle
      } else {
        document.title = '受注管理システム - maider'
      }
    },
    async loginCheck (routeInstance) {
      if (routeInstance.meta.requiresAuth && !this.$auth0.isAuthenticated.value) {
        this.$router.push('/login')
      }
    },
    async profileGetter (idToken) {
      await this.axios.get('https://api-001.maider.jp/user/profile', { headers: { Authorization: 'Bearer ' + idToken } })
        .then(response => {
          this.response = response.data
          this.$store.dispatch('save_profile_nickname', this.response.nickname)
          this.$store.dispatch('save_profile_shopname', this.response.shop_name)
          if (this.response.profile_img) {
            this.$store.dispatch('save_profile_img', `${this.response.profile_img}?tag=${this.response.profile_img_tag}`)
          } else {
            this.$store.dispatch('save_profile_img', this.$store.getters.getUser.picture)
          }
          this.$store.dispatch('save_settings_custom_field', { fieldName: 'fieldName1', fieldValue: this.response.settings.customefilds.field1 })
          this.$store.dispatch('save_settings_custom_field', { fieldName: 'fieldName2', fieldValue: this.response.settings.customefilds.field2 })
          this.$store.dispatch('save_settings_custom_field', { fieldName: 'fieldName3', fieldValue: this.response.settings.customefilds.field3 })
          this.$store.dispatch('save_settings_custom_field', { fieldName: 'fieldName4', fieldValue: this.response.settings.customefilds.field4 })
          this.$store.dispatch('save_settings_custom_field', { fieldName: 'fieldName5', fieldValue: this.response.settings.customefilds.field5 })
        })
        .catch(err => {
          console.error(err.response.status)
        })
    },
    async otherAuthGetter (idToken) {
      await this.axios.get('https://api-001.maider.jp/auth', { headers: { Authorization: 'Bearer ' + idToken } })
        .then(response => {
          this.response = response.data
          this.$store.dispatch('save_minne_link_setting', this.response.minne_link_setting)
          this.$store.dispatch('save_minne_auth_token', this.response.minne_auth_token)
          this.$store.dispatch('save_creema_link_setting', this.response.creema_link_setting)
          this.$store.dispatch('save_creema_session', this.response.creema_session)
          this.$store.dispatch('save_creema_xsrf_token', this.response.creema_xsrf_token)
          this.$store.dispatch('save_base_link_setting', this.response.base_link_setting)
          this.$store.dispatch('save_base_refreshtoken', this.response.base_refreshtoken)
          this.$store.dispatch('save_isTokenGet', true)
        })
        .catch(err => {
          console.error(err.response.status)
        })
    },
    async otherAuthCheck () {
      // BASEのリフレッシュトークンからアクセストークンを取得する
      // アクセストークンが取得できないときはリフレッシュトークンの有効期限切れとしてエラー処理
      if (this.$store.getters.get_base_link_setting === 'M3b6T1e9C4r8') {
        const data = {
          uri: `${encodeURIComponent(new URL(window.location.href).origin)}/settings`
        }
        await this.axios.post(`https://api-001.maider.jp/base/oauth/token?grant_type=refresh_token&code=${this.$store.getters.get_base_refreshtoken}`, data, { headers: { Authorization: 'Bearer ' + this.$store.getters.getAuthToken } })
          .then(response => {
            this.response = response.data
            this.$store.dispatch('save_base_accesstoken', this.response.access_token)
          })
          .catch(() => {
            this.$store.commit('set_otherAuthError', true)
            this.$store.commit('save_base_refreshtoken_expired', true)
          })
      }

      // 連携が正常でないときにヘッダーにエラーを出すようにするための処理
      if (this.$store.getters.get_minne_link_setting !== 'a4J6b8K2h9L0') {
        await this.axios.get(`https://api-001.maider.jp/auth/minne?auth_token=${this.$store.getters.get_minne_auth_token}`, { headers: { Authorization: 'Bearer ' + this.$store.getters.getAuthToken } })
          .catch(() => {
            this.$store.commit('set_otherAuthError', true)
          })
      }

      if (this.$store.getters.get_creema_link_setting !== 'p9X5v2A1B7k3' && !this.$store.getters.getotherAuthError) {
        await this.axios.get(`https://api-001.maider.jp/auth/creema?session=${this.$store.getters.get_creema_session}&XSRF_TOKEN=${this.$store.getters.get_creema_xsrf_token}`, { headers: { Authorization: 'Bearer ' + this.$store.getters.getAuthToken } })
          .catch(() => {
            this.$store.commit('set_otherAuthError', true)
          })
      }
    },
    pageTopAnime () {
      const scroll = window.scrollY
      const pageTopElement = document.getElementById('page-top-button')

      if (scroll >= 200) {
        pageTopElement.classList.remove('DownMove')
        pageTopElement.classList.add('UpMove')
      } else {
        if (pageTopElement.classList.contains('UpMove')) {
          pageTopElement.classList.remove('UpMove')
          pageTopElement.classList.add('DownMove')
        }
      }
    },
    goToTop (event) {
      event.preventDefault()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  // height: calc(100vh - 58.4px);
  position: relative;
}

@media (min-width: 1201px) {
  .home {
    width: calc(100vw - 230px);
    left: 210px;
  }
}

@media (max-width: 1200px) {
  .home {
    max-width: 100vw;
    left: 0;
  }
}

/*リンクの形状*/

#page-top-button {
  position: fixed;
  right: 10px;
  bottom:10px;
  z-index: 2;
    /*はじめは非表示*/
  opacity: 0;
  transform: translateY(100px);

  & a {
    display: flex;
    background: rgba(0,0,0,1);
    border-radius: 50%;
    width: 52px;
    height: 52px;
    transition:all 0.3s;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 7px;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 10px;
      height: 10px;
      margin: auto;
      content: '';
      transform: rotate(-45deg);
      border-top: 1px solid #FFF;
      border-right: 1px solid #FFF;
    }

    &:hover {
      background: #777;
    }
  }

  &.UpMove {
    animation: UpAnime 0.5s forwards;
  }

  &.DownMove {
    animation: DownAnime 0.5s forwards;
  }
}

@keyframes UpAnime {
  from {
    opacity: 0;
  transform: translateY(100px);
  }
  to {
    opacity: 1;
  transform: translateY(0);
  }
}

@keyframes DownAnime {
  from {
    opacity: 1;
  transform: translateY(0);
  }
  to {
    opacity: 1;
  transform: translateY(100px);
  }
}
</style>
