<template>
  <div v-if="this.$store.getters.getisAuth" class="side" v-bind:class="{ otherAuthError : otherAuthError }">
    <div class="mobileSidebar">
      <div class="DrawerMenu" v-bind:class="{ __active : mobileSidebarOpen }">
        <div class="DrawerMenu__panel" v-bind:class="{ __active : mobileSidebarOpen }">
          <div v-if="this.$store.getters.getisAuth" class="DrawerMenu__panel__wrapper">
            <a @click="this.$store.state.mobileSidebarOpen = false" tabindex="-1">
              <div class="DrawerMenu__panel__profile">
                <div class="Avatar__wrapper">
                  <router-link to="/profile">
                    <img :src="`${this.$store.getters.get_profile_img}`" class="Avatar">
                  </router-link>
                </div>
                <div class="Plofile__wrapper">
                  <b>{{ this.$store.getters.get_profile_nickname }}</b>
                  <router-link to="/profile">
                    <p>プロフィールを見る</p>
                  </router-link>
                </div>
              </div>
            </a>
            <div class="Menu">
              <div class="DrawerMenu__divider"></div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headinghome">
                  <h4 class="nav-title">
                    <router-link @click="this.$store.state.mobileSidebarOpen = false" to="/" class="hover-flash">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-home">
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                      </svg>
                      <span>ホーム</span>
                    </router-link>
                  </h4>
                </div>
                <div class="panel-collapse">
                  <ul class="list-group">
                    <li>
                      <router-link @click="this.$store.state.mobileSidebarOpen = false" to="/dashboard" class="hover-flash">ダッシュボード</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingorder">
                  <h4 class="nav-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-shopping-cart">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span>受注管理</span>
                  </h4>
                </div>
                <div class="panel-collapse">
                  <ul class="list-group">
                    <li>
                      <router-link @click="this.$store.state.mobileSidebarOpen = false" to="/order_list" class="hover-flash">受注一覧</router-link>
                    </li>
                    <li>
                      <router-link @click="this.$store.state.mobileSidebarOpen = false" to="/preparing_shipping_list" class="hover-flash">出荷準備中</router-link>
                    </li>
                    <li>
                      <router-link @click="this.$store.state.mobileSidebarOpen = false" to="/shipping_list" class="hover-flash">出荷済み</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingorder">
                  <h4 class="nav-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 96 960 960" fill="currentColor" class="feather feather-shopping-cart">
                      <path d="m261 530 220-354 220 354H261Zm445 446q-74 0-124-50t-50-124q0-74 50-124t124-50q74 0 124 50t50 124q0 74-50 124t-124 50Zm-586-25V647h304v304H120Zm586.085-35Q754 916 787 882.916q33-33.085 33-81Q820 754 786.916 721q-33.085-33-81.001-33Q658 688 625 721.084q-33 33.085-33 81Q592 850 625.084 883q33.085 33 81.001 33ZM180 891h184V707H180v184Zm189-421h224L481 289 369 470Zm112 0ZM364 707Zm342 95Z"/>
                    </svg><span>商品管理</span>
                  </h4>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingorder">
                  <h4 class="nav-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 96 960 960" fill="currentColor" class="feather feather-shopping-cart">
                      <path d="M120 914V422q-14-2-27-20t-13-39V236q0-23 18-41.5t42-18.5h680q23 0 41.5 18.5T880 236v127q0 21-13 39t-27 20v492q0 23-18.5 42.5T780 976H180q-24 0-42-19.5T120 914Zm60-491v493h600V423H180Zm640-60V236H140v127h680ZM360 633h240v-60H360v60ZM180 916V423v493Z"/>
                    </svg><span>在庫管理</span>
                  </h4>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingconf">
                  <h4 class="nav-title">
                    <router-link @click="this.$store.state.mobileSidebarOpen = false" to="/settings" class="hover-flash">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-settings">
                        <circle cx="12" cy="12" r="3"></circle>
                        <path
                          d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                        </path>
                      </svg>
                      <span>各種設定</span>
                    </router-link>
                  </h4>
                </div>
              </div>
              <div class="DrawerMenu__divider"></div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingconf">
                  <h4 class="nav-title">
                    <router-link @click="this.$store.state.mobileSidebarOpen = false" to="/subscription" class="hover-flash">
                      <img class="Menu_panel_icon" src="@/assets/icon/iconizer-description-fill0-wght400-grad0-opsz24.svg">
                      <span>契約情報</span>
                    </router-link>
                  </h4>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingconf">
                  <h4 class="nav-title">
                    <router-link @click="this.$store.state.mobileSidebarOpen = false" to="/logout" class="hover-flash">
                      <img class="Menu_panel_icon" src="@/assets/icon/icon-exit.svg">
                      <span>ログアウト</span>
                    </router-link>
                  </h4>
                </div>
              </div>
            </div>
            <div class="DrawerMenu__footer">
              <div class="Links_group">
                <div class="Sidebar__content__header">
                  <h3 class="Sidebar__content__header__title">お困りの方はこちら</h3>
                </div>
                <ul class="Box__links">
                  <li>
                    <a target="_blank" rel="noopener noreferrer nofollow external"
                      href="https://lp-maider.studio.site/"
                      class="Box__links__a Box__links__a--yay">サービスについて</a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer nofollow external"
                      href="https://lp-maider.studio.site/terms"
                      class="Box__links__a Box__links__a--document">利用規約</a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer nofollow external"
                      href="https://lp-maider.studio.site/policy"
                      class="Box__links__a Box__links__a--privacy">プライバシーポリシー</a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer nofollow external"
                      href="https://lp-maider.studio.site/tokusho"
                      class="Box__links__a Box__links__a--act">特商法に基づく表記</a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer nofollow external"
                      href="https://www.olympus-japan.com/"
                      class="Box__links__a Box__links__a--company">運営会社</a>
                  </li>
                </ul>
                <ul class="version">
                  <li>Version {{ this.version }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div @click="this.$store.state.mobileSidebarOpen = false" class="DrawerMenu__close" role="button"></div>
        </div>
      </div>
    </div>
    <div id="sidebar-wrapper" class="desktopSidebar">
      <div class="panel-group sidebar-nav" id="accordion" role="tablist" aria-multiselectable="true">
        <div class="sidebar-brand">
          <router-link to="/profile">{{ this.$store.getters.get_profile_shopname }}<br>管理者様</router-link>
        </div>
        <div class="panel panel-nav">
          <div class="panel-heading" role="tab" id="headinghome">
            <h4 class="nav-title">
              <router-link to="/" class="hover-flash">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-home">
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg><span>ホーム</span>
              </router-link>
            </h4>
          </div>
          <div id="collapsehome" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headinghome"
            aria-expanded="true" style="">
            <ul class="list-group">
              <li>
                <router-link to="/dashboard" class="hover-flash">ダッシュボード</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel panel-nav">
          <div class="panel-heading" role="tab" id="headingorder">
            <h4 class="nav-title">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-shopping-cart">
                <circle cx="9" cy="21" r="1"></circle>
                <circle cx="20" cy="21" r="1"></circle>
                <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
              </svg><span>受注管理</span>
            </h4>
          </div>
          <div id="collapseorder" class="panel-collapse collapse  " role="tabpanel" aria-labelledby="headingorder"
            aria-expanded="false">
            <ul class="list-group">
              <li>
                <router-link to="/order_list" class="hover-flash">受注一覧</router-link>
              </li>
              <li>
                <router-link to="/preparing_shipping_list" class="hover-flash">出荷準備中</router-link>
              </li>
              <li>
                <router-link to="/shipping_list" class="hover-flash">出荷済み</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel panel-nav disabled">
          <div class="panel-heading" role="tab" id="headingorder">
            <h4 class="nav-title">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 96 960 960" fill="currentColor" class="feather feather-shopping-cart">
                <path d="m261 530 220-354 220 354H261Zm445 446q-74 0-124-50t-50-124q0-74 50-124t124-50q74 0 124 50t50 124q0 74-50 124t-124 50Zm-586-25V647h304v304H120Zm586.085-35Q754 916 787 882.916q33-33.085 33-81Q820 754 786.916 721q-33.085-33-81.001-33Q658 688 625 721.084q-33 33.085-33 81Q592 850 625.084 883q33.085 33 81.001 33ZM180 891h184V707H180v184Zm189-421h224L481 289 369 470Zm112 0ZM364 707Zm342 95Z"/>
              </svg><span>商品管理</span>
            </h4>
          </div>
        </div>
        <div class="panel panel-nav disabled">
          <div class="panel-heading" role="tab" id="headingorder">
            <h4 class="nav-title">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 96 960 960" fill="currentColor" class="feather feather-shopping-cart">
                <path d="M120 914V422q-14-2-27-20t-13-39V236q0-23 18-41.5t42-18.5h680q23 0 41.5 18.5T880 236v127q0 21-13 39t-27 20v492q0 23-18.5 42.5T780 976H180q-24 0-42-19.5T120 914Zm60-491v493h600V423H180Zm640-60V236H140v127h680ZM360 633h240v-60H360v60ZM180 916V423v493Z"/>
              </svg><span>在庫管理</span>
            </h4>
          </div>
        </div>
        <div class="panel panel-nav">
          <div class="panel-heading" role="tab" id="headingconf">
            <h4 class="nav-title">
              <router-link to="/settings" class="hover-flash">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-settings">
                  <circle cx="12" cy="12" r="3"></circle>
                  <path
                    d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                  </path>
                </svg><span>各種設定</span>
              </router-link>
            </h4>
          </div>
        </div>
      </div>
      <div class="Links_group">
        <div class="Sidebar__content__header">
          <h3 class="Sidebar__content__header__title">お困りの方はこちら</h3>
        </div>
        <ul class="Box__links">
          <li>
            <a target="_blank" rel="noopener noreferrer nofollow external"
              href="https://maider.jp/"
              class="Box__links__a Box__links__a--yay">サービスについて</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer nofollow external"
              href="https://lp-maider.studio.site/terms"
              class="Box__links__a Box__links__a--document">利用規約</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer nofollow external"
              href="https://lp-maider.studio.site/policy"
              class="Box__links__a Box__links__a--privacy">プライバシーポリシー</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer nofollow external"
              href="https://lp-maider.studio.site/tokusho"
              class="Box__links__a Box__links__a--act">特商法に基づく表記</a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer nofollow external"
              href="https://www.olympus-japan.com/"
              class="Box__links__a Box__links__a--company">運営会社</a>
          </li>
        </ul>
        <ul class="version">
          <li>Version {{ this.version }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      version: 'β3.1.0'
    }
  },
  computed: {
    mobileSidebarOpen () {
      return this.$store.state.mobileSidebarOpen
    },
    otherAuthError () {
      return this.$store.getters.getotherAuthError
    }
  }
}
</script>

<style scoped lang="scss">
.side {
  margin-top: 58.4px;

  &.otherAuthError {
    margin-top: 114.83px;
  }

  @media (max-width: 700px) {
    &.otherAuthError {
      margin-top: calc(117.12px + 40px);
    }
  }

  @media (min-width: 1201px) {
    .mobileSidebar {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    .desktopSidebar {
      display: none;
    }
  }

  .mobileSidebar {

    .DrawerMenu {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: opacity .4s;
      will-change: opacity;
      display: block;
      visibility: hidden;
      z-index: 1000;
      width: 100%;
      height: 100%;
      transition: .1s ease-in-out;

      &.__active {
        visibility: visible;
        background-color: rgba(0,0,0,.5);
      }

      .DrawerMenu__panel {
        visibility: hidden;
        position: fixed;
        z-index: 5001;
        top: 0;
        bottom: 0;
        left: 0;
        width: 75%;
        height: 100%;
        transform: translateX(-300px);
        transition: .15s ease-in-out;
        background-color: #fff;

        &.__active {
          visibility: visible;
          transform: translateX(0);
        }

        .DrawerMenu__panel__wrapper {
          display: grid;
          grid-row-gap: 20px;
          grid-template-rows: -webkit-max-content 1fr -webkit-max-content;
          grid-template-rows: max-content 1fr max-content;
          height: 100%;
          padding: 40px;
          box-sizing: border-box;
          overflow-x: hidden;
          overflow-y: auto;

          a {
            display: block;
            color: currentcolor;
            text-decoration: none;
            cursor: pointer;
          }

          .DrawerMenu__panel__profile {
            display: grid;
            grid-template-columns: -webkit-max-content 1fr;
            grid-template-columns: max-content 1fr;

            .Avatar__wrapper {
              position: relative;
              width: 55px;
              height: 55px;
              margin-right: 20px;

              .Avatar {
                width: 55px;
                height: 55px;
                border-radius: 50%;
                object-fit: cover;
              }
            }

            .Plofile__wrapper {
              display: flex;
              flex-direction: column;
              justify-content: center;

              p {
                color: #888;
              }
            }
          }

          .Menu {
            .panel {
              a.hover-flash {
                transition: .3s;
              }

              a.hover-flash:hover {
                background-color: #f7f7f7;
              }

              .panel-heading {
                font-size: 16.5px;
                line-height: 40px;

                h4.nav-title {
                  display: block;
                  width: 100%;
                  text-indent: 15px;
                  padding: 5px 0;
                  color: #294661;
                  letter-spacing: 0.6px;

                  svg.feather,
                  img.Menu_panel_icon {
                    height: 22px;
                    width: 22px;
                    margin: 0 16px -5px 0;
                  }

                  img.Menu_panel_icon {
                    filter: brightness(0) saturate(100%) invert(25%) sepia(14%) saturate(1543%) hue-rotate(168deg) brightness(96%) contrast(97%);
                  }
                }
              }

              .panel-collapse {
                display: block;

                .list-group {
                  list-style: none;

                  li {
                    text-indent: 30px;
                    line-height: 30px;
                    margin-bottom: 5px;

                    a {
                      color: #294661;
                      padding: 3px 0;
                      font-size: 15.5px;
                    }
                  }
                }
              }

              .sidebar-nav .panel-nav {
                .panel-heading {

                  a.hover-flash:hover {
                    background-color: #f7f6f6;
                  }

                  .nav-title {
                    a {
                      color: #294661;
                      display: block;
                      text-decoration: none;
                    }
                  }
                }
              }
            }
            .DrawerMenu__divider {
              width: 100%;
              height: 1px;
              margin: 10px 0;
              background-color: #e0e0e0;
            }
          }
          .DrawerMenu__footer {
            display: grid;
            grid-gap: 20px;
            gap: 20px;

            .Links_group {
              width: 100%;
              padding: 0;

              li {
                list-style-type: none;
              }

              .Sidebar__content__header {
                padding: 20px 0 7px;
                border-bottom: 1px solid #212121;

                .Sidebar__content__header__title {
                  font-size: 16.5px;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  height: 100%;

                  &:before {
                    content: "";
                    display: block;
                    width: 22px;
                    height: 22px;
                    margin: 0 10px 2.5px 0;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-size: contain;
                  }

                  &:before {
                    background-image: url(@/assets/icon/help_FILL0_wght400_GRAD0_opsz48.svg);
                  }
                }
              }

              .Box__links {
                display: grid;
                grid-gap: 20px;
                grid-template-columns: 1fr;
                padding: 18px 0;

                .Box__links__a {
                  display: grid;
                  grid-template-columns: 1fr;
                  align-items: center;
                  color: #888;
                  text-decoration: none;
                  text-indent: 25px;
                  font-size: 15.5px;
                }
              }

              .version {
                font-size: 14px;
                font-weight: bold;
                padding: 25px 5px;
                text-indent: 15px;
              }
            }
          }
        }

        .DrawerMenu__close {
          position: absolute;
          top: 40px;
          left: calc(100% + 40px);
          width: 30px;
          height: 30px;
          padding: 5px 5px 5px 0;
          cursor: pointer;

          &:before {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(https://yay.space/images/icon-x-white.svg);
            background-repeat: no-repeat;
            background-position: 0;
            background-size: contain;
          }
        }
      }
    }
  }

  &.otherAuthError {
    .desktopSidebar#sidebar-wrapper {
      height: calc(100% - 115px);

      @media (max-height: 870px) {
        .Links_group {
          bottom: inherit;
          top: 440px
        }
      }
    }
  }

  .desktopSidebar {

    &#sidebar-wrapper {
      white-space: normal;
      width: 210px;
      overflow: hidden;
      position: fixed;
      height: calc(100% - 61px);
      overflow-y: auto;
      background: #f0f0f0;
      border-right: solid 1px #ddd;
      transition: all 0.5s ease;

      .sidebar-nav {
        position: absolute;
        top: 0;
        width: 100%;
        padding: 0;
        margin: 0;

        .sidebar-brand {
          text-indent: 0px;
          margin-left: 10px;
          padding: 5px 5px;
          font-size: 14.5px;
          color: #294661;
          line-height: 26px;
          border-bottom: solid 1px #ddd;

          & a {
            color: #294661;
            text-decoration: none;
          }
        }

        .panel {
          margin: 0;
          background-color: transparent;
          box-shadow: none;
          cursor: default;

          .panel-heading {
            text-indent: 10px;
            line-height: 40px;
            padding: 0px;
          }
        }

        .panel-nav h4.nav-title {
          font-size: 14px;
          margin: 0px;
          margin-bottom: 0px;
          padding: 0;
        }

        .panel-nav .nav-title {
          display: block;
          width: 100%;
          padding: 10px 5px;
          text-decoration: none;
          color: #294661;
          text-align: left;

          svg.feather {
            height: 22px;
            width: 22px;
            margin: 0 8px -5px 0;
          }
        }
      }

      .collapse.in {
        display: block;
      }

      .sidebar-nav .panel-nav {
        .panel-heading {

          a.hover-flash:hover {
            background-color: #f7f6f6;
          }

          .nav-title {
            a {
              color: #294661;
              display: block;
              text-decoration: none;
            }
          }
        }

        .list-group {
          border-top: none !important;
          list-style: none;
          margin: 0;

          li {
            text-indent: 20px;
            line-height: 30px;

            a {
              color: #294661;
              text-indent: 40px;
              display: block;
              text-decoration: none;
              padding: 3px 0;
              font-size: 15.5px;
            }

            a.hover-flash {
              transition: .3s;
            }

            a.hover-flash:hover {
              background-color: #f7f7f7;
            }
          }
        }
      }

      .Links_group {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0;
        margin: 5px 0;

        li {
          list-style-type: none;
        }

        .Sidebar__content__header {
          margin: 0 20px;
          padding: 20px 0 7px;
          border-bottom: 1px solid #212121;

          .Sidebar__content__header__title {
            font-size: 14px;
            font-weight: bold;
            display: flex;
            align-items: center;
            height: 100%;

            &:before {
              content: "";
              display: block;
              width: 20px;
              height: 20px;
              margin: 0 5px 1px 0;
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: contain;
            }

            &:before {
              background-image: url(@/assets/icon/help_FILL0_wght400_GRAD0_opsz48.svg);
            }
          }
        }

        .Box__links {
          display: grid;
          grid-gap: 10px;
          grid-template-columns: 1fr;
          padding: 15px;

          .Box__links__a {
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            color: #888;
            text-decoration: none;
            text-indent: 10px;
            font-size: 13px;
          }
        }

        .version {
          font-size: 14px;
          font-weight: bold;
          padding: 5px 5px 15px;
          text-indent: 15px;
        }
      }

      @media (max-height: 820px) {
        .Links_group {
          bottom: inherit;
          top: 445px
        }
      }
    }
  }
}
</style>
